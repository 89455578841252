
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'App/components/utils/ConfirmDialog';
import { permissionDict as p } from 'Services/permissions/permission-dict';

import React, { Fragment, useMemo, useState } from 'react';
import { useAppUser } from '../../../utils/providers/AppUserProvider';
import { blankLink } from '../../../../../config';
import EntityInfoCell from 'App/components/entity-info/EntityInfoCell';
import ReactTooltip from 'react-tooltip';
import { TooltipType } from 'App/ui-utils';

export const ClaimDocumentsColumnDefinition = ( deleteDocumentHandler: ( code: string ) => Promise<void>,
  getDocumentDownloadUrlHandler: ( event: React.MouseEvent<HTMLAnchorElement>, code: string ) => Promise<void> ) => {
  const { t } = useTranslation( [ 'claim', 'base' ] );
  const user = useAppUser();

  const renderTooltip = ( idName: string, text: string, theme: TooltipType = 'light' ) => (
    <ReactTooltip class="custom-tooltip-box break-word" id={ `tooltip-${idName}` } place="right" type={ theme }>
      { text }
    </ReactTooltip>
  );

  return useMemo( () => {
    return [
      {
        Header: t( 'documents.tableHeaders.name' ),
        accessor: 'documentId',
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => {
          const fileName = document.filename;
          const tooltipLabel = renderTooltip( `filename-${document.id}`, document.filename || '' );
          return (
            <>
              { tooltipLabel }
              <a
                href={ blankLink }
                onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
                  getDocumentDownloadUrlHandler( e, document.code );
                } }
                data-tip data-for={ `tooltip-filename-${document.id}` }
              >
                <i className="material-icons material-icons-outlined f-18 align-vertical-sub c-blue p-r-10">
                  file_download
                </i>
                <span style={ { verticalAlign: 'text-bottom' } }>
                  { fileName }
                </span>
              </a>
            </>
          );
        },
        width: '40%',
        minWidth: 40,
      },
      {
        Header: t( 'base:infocell.createdOnBy' ),
        accessor: 'documentUpdatedAt',
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => (
          <EntityInfoCell entity={ document } />
        ),
        width: '54%',
        minWidth: 54,
      },
      {
        Header: '',
        id: 'deleteDocButton',
        canSort: false,
        // eslint-disable-next-line react/display-name
        Cell: ( { row: { original: document } } ) => {
          const [ showModal, setShowModal ] = useState( false );
          return (
            <Fragment>
              { showModal &&
              <ConfirmModal
                title={ t( 'documents.deleteModal.header' ) }
                type="warning"
                iconColorClass="text-danger"
                cancelButtonLabel={ t( 'actions.cancel' ) }
                confirmButtonLabel={ t( 'actions.delete' ) }
                onClose={ () => {setShowModal( false );} }
                onConfirm={ async () => {
                  await deleteDocumentHandler( document.code );
                } }
              >
                { t( 'documents.deleteModal.message', { filename: document.filename } ) }
              </ConfirmModal>
              }
              { user.hasPermissions( [
                [ p.eis_claims_delete ],
                [ p.document_management_documents_delete ],
              ] ) && (
                <span
                  onClick={ async () => {
                    setShowModal( true );
                  } }
                  className="d-flex justify-content-end pr-1"
                >
                  <i
                    className="material-icons material-icons-outlined f-18 align-vertical-sub c-blue chevron"
                  >delete
                  </i>
                </span> ) }
            </Fragment>
          );
        },
        width: '6%',
        minWidth: 6,
      },
    ];
  }, [ deleteDocumentHandler, getDocumentDownloadUrlHandler, t, user ] );
};
