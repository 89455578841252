import { IAppPermissionDict, PermissionType } from './interfaces';

// Function to generate permission keys
const generatePermissions = (
  prefix: string, actions: string[] = [ 'all', 'view', 'create', 'update', 'delete' ],
): Record<string, PermissionType> => {
  const prefixEntity = prefix.replace( /-|\./g, '_' );

  return actions.reduce( ( acc, action ) => {
    acc[`${prefixEntity}_${action}`] = `${prefix}.${action === 'all' ? '*' : action}`;
    return acc;
  }, {} as Record<string, PermissionType> );
};

const permissionDictOld: IAppPermissionDict = {
  ts_All: 'ts:*',
  ts_ListTickets: 'ts:ListTickets',
  is_All: 'is:*',
  is_ListPolicies: 'is:ListPolicies',
  is_GetPolicy: 'is:GetPolicy',
  is_CreatePolicy: 'is:CreatePolicy',
  is_ListVwbInbox: 'is:ListVwb',
  as_All: 'as:*',
  as_ListAccounts: 'as:ListAccounts',
  as_GetAccount: 'as:GetAccount',
  pb_All: 'pb:*',
  pb_ListDashboards: 'pb:ListDashboards',
  tnts_All: 'tnts:*',
  tnts_ListSettings: 'tnts:ListSettings',
  ds_All: 'ds:*',
};

export const permissionDict: IAppPermissionDict = {
  ...permissionDictOld,
  admin: '*.*',
  ...generatePermissions( 'eis', [ 'all' ] ),
  ...generatePermissions( 'eis.homepage', [ 'all' ] ),
  ...generatePermissions( 'pbm', [ 'all' ] ),
  ...generatePermissions( 'pbm.dashboard' ),
  ...generatePermissions( 'pbm.alerts' ),
  ...generatePermissions( 'pbm.reports' ),
  ...generatePermissions( 'pbm.tariffcomparisons' ),
  ...generatePermissions( 'pbm.scenarioanalyses' ),
  ...generatePermissions( 'tenants', [ 'all' ] ),
  ...generatePermissions( 'tenants.settings' ),
  ...generatePermissions( 'tenants.users' ),
  ...generatePermissions( 'tenants.roles' ),
  ...generatePermissions( 'eis.policy' ),
  ...generatePermissions( 'eis.claims' ),
  ...generatePermissions( 'eis.settings' ),
  ...generatePermissions( 'eis.products' ),
  ...generatePermissions( 'eis.leads' ),
  ...generatePermissions( 'eis.workflows' ),
  ...generatePermissions( 'gdv' ),
  ...generatePermissions( 'gdv-management', [ 'all' ] ),
  ...generatePermissions( 'gdv-management.operations' ),
  ...generatePermissions( 'accounting-management', [ 'all' ] ),
  ...generatePermissions( 'accounting-management.accounts' ),
  ...generatePermissions( 'billing-management', [ 'all' ] ),
  ...generatePermissions( 'billing-management.invoices' ),
  ...generatePermissions( 'commission-management', [ 'all' ] ),
  ...generatePermissions( 'commission-management.agreements' ),
  ...generatePermissions( 'claim-management', [ 'all' ] ),
  ...generatePermissions( 'claim-management.claims' ),
  ...generatePermissions( 'claim-management.regulations' ),
  ...generatePermissions( 'claim-management.statuses' ),
  ...generatePermissions( 'claim-management.partners' ),
  ...generatePermissions( 'claim-management.partner-roles' ),
  ...generatePermissions( 'document-management', [ 'all' ] ),
  ...generatePermissions( 'document-management.documents' ),
  ...generatePermissions( 'document-management.templates' ),
  ...generatePermissions( 'document-management.layouts' ),
  ...generatePermissions( 'document-management.docx-templates' ),
  ...generatePermissions( 'identity-management', [ 'all' ] ),
  ...generatePermissions( 'identity-management.roles' ),
  ...generatePermissions( 'identity-management.permissions' ),
  ...generatePermissions( 'demo', [ 'all', 'view' ] ),
  ...generatePermissions( 'notification-management', [ 'all' ] ),
  ...generatePermissions( 'notification-management.email-templates' ),
  ...generatePermissions( 'partner-management', [ 'all' ] ),
  ...generatePermissions( 'partner-management.partners' ),
  ...generatePermissions( 'policy-management', [ 'all' ] ),
  ...generatePermissions( 'policy-management.leads' ),
  ...generatePermissions( 'payment-management', [ 'all' ] ),
  ...generatePermissions( 'payment-management.payments' ),
  ...generatePermissions( 'payment-management.bank-accounts' ),
  ...generatePermissions( 'policy-management.policies' ),
  ...generatePermissions( 'policy-management.products' ),
  ...generatePermissions( 'process-management', [ 'all' ] ),
  ...generatePermissions( 'process-management.dmns' ),
  ...generatePermissions( 'process-management.workflows' ),
  ...generatePermissions( 'process-management.workflows', [ 'run' ] ),
  ...generatePermissions( 'tenant-management', [ 'all' ] ),
  ...generatePermissions( 'tenant-management.organizations' ),
  ...generatePermissions( 'tenant-management.users' ),
  ...generatePermissions( 'tenant-management.settings' ),
  ...generatePermissions( 'translation-management', [ 'all' ] ),
  ...generatePermissions( 'translation-management.translations' ),
  ...generatePermissions( 'reporting.dashboards' ),
  locked_override_management_all: 'locked-override-management.all.*',
  eis_finance: 'eis.finance.*',
  eis_finance_invoices: 'eis.finance.invoices',
  gdv_view_dot: 'gdv.view.',
  brokers_association: 'brokers.association.*',
  brokers_marketing_agent: 'brokers.marketingAgent.*',
  brokers_broker: 'brokers.broker.*',
  workflows_workflow_create: 'workflows.workflow.create',
  feature_beta: 'feature.beta.*',
};
