import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { apiDateTimeFormat, policyDateFormat } from 'App/ui-utils';
import { ApiResponse, BaseRequest, IApiService, PagingRequest } from 'Services/base';
import { IVariableValue, IProductItem } from 'Services/products/interfaces';
import { JsonObject } from '@cover42/protobuf-util';
import {
  DraftPolicyUpdate,
  ISummaryLabelSettings,
  ITerminatePolicyRequest,
  SummaryLabelSettingsRequest,
  SummaryLabelSettingsResponse,
} from '../interfaces';
import {
  GetSummaryLabelSettingsRequest,
  SetSummaryLabelSettingsRequest,
  TerminatePolicyRequest,
  WithdrawtPolicyRequest,
} from '../requests';
import {
  PremiumFormulaTypes,
  PremiumFormulaUnits,
  PremiumFormulaItemTypes,
  PremiumFormulaVisibilities,
} from '@cover42/ts-contracts';
import { getQueryParamsForListAPI } from 'Services/api';
import { PolicyRevisionStatus } from '../enums';

dayjs.extend( utc );

const insuranceServiceApiServer = process.env.REACT_APP_INSURANCE_SERVICE_API_SERVER ?? '';
const insuranceService = `${insuranceServiceApiServer}/insuranceservice`;

export interface IApiPolicyItem {
  id: number;
  code: string;
  policyNumber: string;
  productId: number;
  productSlug: string;
  accountCode: string;
  productVersionId: number;
  accountId: number;
  status: string;
  createdAt: string;
  policyStartDate: string;
  updatedAt: string;
  versions?: IApiPolicyVersion[];
  ern: string;
}

interface IApiPolicyVersion {
  id: number;
  isCurrent: boolean;
  isDraft: boolean;
  metadata: IPolicyVersionMetadata;
  createdAt: string;
  updatedAt: string;
  timeline?: IApiPolicyTimeline[];
  revisionStatus: PolicyRevisionStatus;
}

interface IApiPolicyTimeline {
  id: number;
  policyObjects: IPolicyObjects[];
  premium: Premium;
  from: string;
  to?: string;
  createdAt: string;
  updatedAt: string;
}

interface IApiPolicyUpdate {
  policyObjects: IPolicyObjectsUpdate[];
  from: string;
  to?: string;
}

interface ApiDraftPolicyUpdate {
  policyObjects?: IPolicyObjectsUpdate[];
  from: string;
  to?: string;
  revisionStatus: PolicyRevisionStatus;
}

export interface IPolicyUpdate {
  policyObjects: IPolicyObjectsUpdate[];
  from: Date;
  to?: Date;
}

export interface PolicyUpdateItem {
  policyObjects: IPolicyObjectsUpdate[];
  premium?: Premium;
  from: Date;
  to?: Date;
}

export interface IPolicyVersionMetadata {
  fields: Record<string, IVariableValue>;
}

export interface IPolicyItem {
  id: number;
  code: string;
  policyNumber: string;
  leadCode?: string;
  productId: number;
  productSlug: string;
  accountCode: string;
  productVersionId: number;
  accountId: number;
  status: string;
  createdAt: dayjs.Dayjs | Date;
  updatedAt: dayjs.Dayjs | Date;
  policyStartDate?: dayjs.Dayjs | Date;
  versions?: IPolicyVersion[];
  product?: IProductItem;
  ern: string;
}

export interface PolicyDraftItem {
  data?: IPolicyItem;
}

export interface IApiListResponse<ItemsType> {
  items: ItemsType[];
  nextPageToken: string;
  itemsPerPage?: number;
  totalItems?: number;
  error?: any;
}

export interface IPolicyVersion {
  id: number;
  isCurrent: boolean;
  isDraft: boolean;
  metadata: IPolicyVersionMetadata;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  timeline?: IPolicyTimeline[];
  revisionStatus?: PolicyRevisionStatus;
}

export interface IPolicyObjects {
  insuredObjectId: number;
  insuredObjectName: string;
  insuredObjectLabel?: string;
  code?: string;
  position?: number;
  summary: string;
  data: JsonObject;
  isMultiInsuredObject?: boolean;
}

export interface IPolicyObjectsUpdate {
  insuredObjectName: string;
  insuredObjectId: number;
  data: JsonObject;
  position?: number;
  code?: string;
}

export interface PremiumFormula {
  id: number;
  name: string;
  group: string;
  productVersionId: number;
  expression: string;
  taxRate: number;
  type: PremiumFormulaTypes;
  unit: PremiumFormulaUnits;
  itemType: PremiumFormulaItemTypes;
  visibility: PremiumFormulaVisibilities;
  varialbleName: string;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export interface PremiumItem {
  isOverride: boolean;
  premiumFormulaId: number;
  total: number;
  premiumFormula: PremiumFormula;
  createdAt: string;
  updatedAt: string;
}

export interface Premium {
  grandTotal: number;
  grossTotal: number;
  premiumItems: PremiumItem[];
  createdAt: string;
  updatedAt: string;
  currency?: string;
}

export interface IPolicyTimeline {
  id: number;
  policyObjects: IPolicyObjects[];
  premium?: Premium;
  from: dayjs.Dayjs;
  to?: dayjs.Dayjs;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
}

const mapApiPolicyItemToPolicyItem = ( input: IApiPolicyItem ): IPolicyItem => {
  return {
    ...input,
    createdAt: new Date( input.createdAt ),
    updatedAt: new Date( input.updatedAt ),
    policyStartDate: input.policyStartDate ? new Date( input.policyStartDate ) : new Date( input.createdAt ),
    versions: input.versions ? input.versions.map( mapApiPolicyVersionToPolicyVersion ) : undefined,
  };
};

const mapApiPolicyVersionToPolicyVersion = ( input: IApiPolicyVersion ): IPolicyVersion => {
  return {
    ...input,
    createdAt: dayjs( input.createdAt ),
    updatedAt: dayjs( input.updatedAt ),
    timeline: input.timeline?.map( mapApiPolicyTimelineToPolicyTimeline ),
  };
};

const mapApiPolicyTimelineToPolicyTimeline = ( input: IApiPolicyTimeline ): IPolicyTimeline => {
  return {
    ...input,
    createdAt: dayjs( input.createdAt ),
    updatedAt: dayjs( input.updatedAt ),
    from: dayjs( input.from ),
    to: input.to ? dayjs( input.to ) : undefined,
    policyObjects: input.policyObjects.reverse(),
  };
};

const mapPolicyUpdateToApiPolicyUpdate = ( input: IPolicyUpdate ): IApiPolicyUpdate => {
  const formatFromDate = dayjs( input.from ).format( policyDateFormat );
  const formatToDate = input.to ? dayjs( input.to ).format( policyDateFormat ) : undefined;
  const fromDate = dayjs.utc( formatFromDate ).format( apiDateTimeFormat );
  const toDate = formatToDate ? dayjs.utc( formatToDate ).format( apiDateTimeFormat ) : undefined;

  return {
    ...input,
    from: fromDate,
    to: toDate,
  };
};

const mapDraftPolicyUpdateToApiPolicyUpdate = ( input: DraftPolicyUpdate ): ApiDraftPolicyUpdate => {
  const formatFromDate = dayjs( input.from ).format( policyDateFormat );
  const formatToDate = input.to ? dayjs( input.to ).format( policyDateFormat ) : undefined;
  const fromDate = dayjs.utc( formatFromDate ).format( apiDateTimeFormat );
  const toDate = formatToDate ? dayjs.utc( formatToDate ).format( apiDateTimeFormat ) : undefined;

  return {
    ...input,
    from: fromDate,
    to: toDate,
  };
};

type PolicyRequestExpandType = 'versions' | 'timelines' | 'product' | 'premiumFormulas' | 'currentPremiumFormulas';

export interface IPolicyService {
  getPolicy( policyCode: string, expand?: PolicyRequestExpandType[] ): Promise<IPolicyItem>;
  updatePolicy( policyCode: string, policyUpdate: IPolicyUpdate ): Promise<IPolicyItem>;
  getPoliciesData( paging: PagingRequest, searchValue: string, expand?: PolicyRequestExpandType[] ):
  Promise<IApiListResponse<IPolicyItem>>;
  getPolicyObject( policyObjectCode: string, policyCode: string ): Promise<IPolicyObjects|null>;
  getPolicyObjects( policyCode: string, insuredObjectId: number, paging: PagingRequest, searchValue: string ):
  Promise<IApiListResponse<IPolicyObjects>>;
  listPolicyVersions( policyCode: string, paging: PagingRequest ): Promise<IApiListResponse<IPolicyVersion>>;
  setSummaryLabelSettings( settings: SummaryLabelSettingsRequest ): Promise<SummaryLabelSettingsResponse>;
  getSummaryLabelSettings( productCode: string ): Promise<ISummaryLabelSettings[]>;
  terminatePolicy( policyCode: string, formData: ITerminatePolicyRequest ): Promise<IPolicyItem>;
  withdrawPolicy( policyCode: string ): Promise<IApiPolicyItem>;
  getPolicyDraft( policyCode: string, expand?: PolicyRequestExpandType[] ): Promise<PolicyDraftItem>;
  createDraftPolicy( policyCode: string, policyUpdate: IPolicyUpdate ): Promise<IPolicyItem>;
  updateDraftPolicy( policyCode: string, draftPolicyUpdate: DraftPolicyUpdate ): Promise<IPolicyItem>;
  deleteDraftPolicy( policyCode: string ): Promise<ApiResponse>;
}

export class PolicyService implements IPolicyService {
  private api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  getPolicy( policyCode: string, expand: PolicyRequestExpandType[] = [ 'versions' ] ): Promise<IPolicyItem> {
    return this.api.request( new GetPolicyRequest( policyCode, expand ) )
      .then( ( { policy } ) => {
        return mapApiPolicyItemToPolicyItem( policy );
      } )
      .catch( ( e ) => {
        throw new Error( 'Policy does not exist' );
      } );
  }

  updatePolicy( policyCode: string, policyUpdate: IPolicyUpdate ): Promise<IPolicyItem> {
    const payload = mapPolicyUpdateToApiPolicyUpdate( policyUpdate );
    const request = new UpdatePolicyRequest( policyCode, payload );
    return this.api.request( request )
      .then( ( { policy } ) => {
        return mapApiPolicyItemToPolicyItem( policy );
      } );
  }

  getPoliciesData( paging: PagingRequest, searchValue: string, expand?: PolicyRequestExpandType[] ):
  Promise<IApiListResponse<IPolicyItem>> {
    return this.api.request( new ListPoliciesRequest( paging, searchValue, expand ) )
      .then( ( response ) => {
        let policyData : IPolicyItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          policyData = items.map( ( item, index ) => {
            return mapApiPolicyItemToPolicyItem( item );
          } );
        }
        const res: IApiListResponse<IPolicyItem> = {
          items: policyData,
          nextPageToken: policyData ? policyData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getPolicyObject( policyObjectCode: string, policyCode: string ): Promise<IPolicyObjects|null> {
    return this.api.request( new ListPolicyObjectsRequest( {
      pageSize: 1,
      pageToken: 1,
      filter: [
        { id: 'code', value: policyObjectCode },
        { id: 'policyCode', value: policyCode },
      ],
      orderBy: [],
    }, '' ) )
      .then( ( response ) => {
        const { items } = response;

        return items && items.length ? items[0] : null;
      } );
  }

  getPolicyObjects( policyCode: string, insuredObjectId: number, paging: PagingRequest, searchValue: string ):
  Promise<IApiListResponse<IPolicyObjects>> {
    paging.filter = [
      { id: 'policyCode', value: policyCode },
      { id: 'insuredObjectId', value: `${insuredObjectId}` },
    ];

    return this.api.request( new ListPolicyObjectsRequest( paging, searchValue ) )
      .then( ( response ) => {
        const { items, nextPageToken } = response;

        const res: IApiListResponse<IPolicyObjects> = {
          ...response,
          items: items || [],
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  async listPolicyVersions( policyCode: string, paging: PagingRequest ): Promise<IApiListResponse<IPolicyVersion>> {
    return this.api.request( new ListPolicyVersionsRequest( policyCode, paging ) )
      .then( ( response ) => {
        const { items, nextPageToken } = response;

        const res: IApiListResponse<IPolicyVersion> = {
          items,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  async setSummaryLabelSettings( settings: SummaryLabelSettingsRequest ): Promise<SummaryLabelSettingsResponse> {
    const response = await this.api.request( new SetSummaryLabelSettingsRequest( settings ) );

    return response;
  }

  async getSummaryLabelSettings( productCode: string ): Promise<ISummaryLabelSettings[]> {
    const response = await this.api.request( new GetSummaryLabelSettingsRequest( productCode ) );
    const { values } = response;

    return values;
  }

  async terminatePolicy( policyCode: string, formData: ITerminatePolicyRequest ): Promise<IPolicyItem> {
    const response = await this.api.request( new TerminatePolicyRequest( policyCode, formData ) );

    const { policy } = response;

    return mapApiPolicyItemToPolicyItem( policy );
  }

  async withdrawPolicy( policyCode: string ): Promise<IApiPolicyItem> {
    const response = await this.api.request( new WithdrawtPolicyRequest( policyCode ) );

    const { policy } = response;

    return policy;
  }

  async getPolicyDraft(
    policyCode: string, expand: PolicyRequestExpandType[] = [ 'versions' ],
  ): Promise<PolicyDraftItem> {
    const res: PolicyDraftItem = {
      data: undefined,
    };

    try {
      const response = await this.api.request( new GetPolicyDraftRequest( policyCode, expand ) );

      const { policy } = response;

      res.data = mapApiPolicyItemToPolicyItem( policy );

      return res;
    } catch ( error ) {
      return Promise.resolve( res );
    }
  }

  async createDraftPolicy( policyCode: string, policyUpdate: IPolicyUpdate ): Promise<IPolicyItem> {
    const payload = mapPolicyUpdateToApiPolicyUpdate( policyUpdate );
    const response = await this.api.request( new CreateDraftPolicyRequest( policyCode, payload ) );

    const { policy } = response;

    return mapApiPolicyItemToPolicyItem( policy );
  }

  async updateDraftPolicy( policyCode: string, draftPolicyUpdate: DraftPolicyUpdate ): Promise<IPolicyItem> {
    const payload = mapDraftPolicyUpdateToApiPolicyUpdate( draftPolicyUpdate );
    const response = await this.api.request( new UpdateDraftPolicyRequest( policyCode, payload ) );

    const { policy } = response;

    return mapApiPolicyItemToPolicyItem( policy );
  }

  async deleteDraftPolicy( policyCode: string ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteDraftPolicyRequest( policyCode ) );

    return response;
  }
}

class GetPolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    expand: PolicyRequestExpandType[] = [ 'versions' ],
  ) {

    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/policies/${policyCode}?${expandPars}`,
      responseType: 'json',
    } );
  }
}

export class UpdatePolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    payload: IApiPolicyUpdate,
  ) {
    super( {
      method: 'PUT',
      url: `${insuranceService}/v1/policies/${policyCode}`,
      responseType: 'json',
      data: payload,
    } );
  }
}

class ListPolicyVersionsRequest extends BaseRequest<IApiListResponse<IPolicyVersion>> {
  constructor(
    policyCode: string,
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: `${insuranceService}/v1/policies/${policyCode}/versions`,
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, undefined, 'search', ':' ),
    } );
  }
}

export class ListPoliciesRequest extends BaseRequest<IApiListResponse<IApiPolicyItem>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
    expand: PolicyRequestExpandType[] = [ 'timelines', 'product' ],
  ) {

    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );

    if ( paging.pageToken > 1 ) {
      queryParams.set( 'pageToken', paging.pageToken.toString() );
    }

    if ( paging.filter.length > 0 ) {
      const filterPars = paging.filter.map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` ).join( '&' );
      queryParams.set( 'filter', filterPars );
    }

    if ( paging.orderBy.length > 0 ) {
      const orderPars = paging.orderBy.map( ( item ) => `${item.id}:${ item.desc ? 'DESC' : 'ASC' }` ).join( '&' );
      queryParams.set( 'order', orderPars );
    }

    if ( searchValue.length > 0 ) {
      queryParams.set( 'search', searchValue );
    }

    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/policies?${expandPars}`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListPolicyObjectsRequest extends BaseRequest<IApiListResponse<IPolicyObjects>> {
  constructor(
    paging: PagingRequest,
    searchValue: string,
  ) {
    super( {
      method: 'GET',
      url: `${insuranceService}/v1/policies/policy-objects`,
      responseType: 'json',
      params: getQueryParamsForListAPI( paging, searchValue, 'search', ':' ),
    } );
  }
}

class GetPolicyDraftRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    expand: PolicyRequestExpandType[] = [ 'versions' ],
  ) {

    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${insuranceService}/v1/policies/${policyCode}/draft?${expandPars}`,
      responseType: 'json',
    } );
  }
}

export class CreateDraftPolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    payload: IApiPolicyUpdate,
  ) {
    super( {
      method: 'POST',
      url: `${insuranceService}/v1/policies/${policyCode}/draft`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class UpdateDraftPolicyRequest extends BaseRequest<{ policy: IApiPolicyItem }> {
  constructor(
    policyCode: string,
    payload: ApiDraftPolicyUpdate,
  ) {
    super( {
      method: 'PATCH',
      url: `${insuranceService}/v1/policies/${policyCode}/draft`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class DeleteDraftPolicyRequest extends BaseRequest<ApiResponse> {
  constructor( policyCode: string ) {
    super( {
      method: 'DELETE',
      url: `${insuranceService}/v1/policies/${policyCode}/draft`,
      responseType: 'json',
    } );
  }
}

export const PolicyServiceContext: React.Context<IPolicyService> = React.createContext( undefined as any );

export const usePolicyService = (): IPolicyService => {
  return React.useContext( PolicyServiceContext );
};

export const useDynamicPolicyService = ( api: IApiService ): IPolicyService => {
  return React.useContext( React.createContext( new PolicyService( api ) ) );
};
