import React from 'react';
import { LazyComponentType, RouteDescriptor } from './types';
import { permissionDict as p } from 'Services/permissions/permission-dict';
import { pbmID } from 'App/components/utils/providers/AppProductProvider';

// Components with layout
const NewWorkFlow: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/AddNew' ) );
const WorkFlowList: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/MainList' ) );
const WorkFlowView: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/ViewDetails' ) );
const WorkflowInstanceListPage: LazyComponentType = React.lazy( () =>
    import( './App/components/management/workflow-instance-monitor/workflow-instance-list/WorkflowInstanceListPage' ) );
const WorkFlowMonitorView: LazyComponentType = React.lazy( () =>
  import(
    './App/components/management/workflow-instance-monitor/workflow-instance-details/WorkFlowInstanceDetailsPage' ),
);
const PBMHome: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/home/home' ) );
const ScenarioAnalyses: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/scenario-analyses/ScenarioAnalyses' ) );
const CreateScenarioAnalysis: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/scenario-analyses/CreateScenarioAnalysis' ) );
const ScenarioAnalysisDetails: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/scenario-analyses/ScenarioAnalysisDetails' ) );
const Dashboards: LazyComponentType = React.lazy( () =>
import( './App/components/management/dashboard/Dashboards' ) );
const CreateDashboard: LazyComponentType = React.lazy( () =>
import( './App/components/management/dashboard/CreateDashboard' ) );
const EditDashboard: LazyComponentType = React.lazy( () =>
import( './App/components/management/dashboard/EditDashboard' ) );
const DashboardDetails: LazyComponentType = React.lazy( () =>
import( './App/components/management/dashboard/DashboardDetails' ) );
const Alerts: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/alerts/Alerts' ) );
const AddNewAlert: LazyComponentType = React.lazy( () =>
  import( './App/components/pbm/alerts/AddAlert' ) );
const EditAlert: LazyComponentType = React.lazy( () =>
  import( './App/components/pbm/alerts/EditAlert' ) );
const AlertHistories: LazyComponentType = React.lazy( () =>
  import( './App/components/pbm/alerts/Histories' ) );
const ViewDashboard: LazyComponentType = React.lazy( () =>
  import( './App/components/pbm/alerts/ViewDashboard' ) );
const Reports: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/reports/Reports' ) );
const ReportHistory: LazyComponentType = React.lazy( () =>
import( './App/components/pbm/reports/ReportHistory' ) );
const GeneralSettings: LazyComponentType = React.lazy( () =>
  import( './App/components/management/GeneralSettings' ) );
const PaymentProviders: LazyComponentType = React.lazy( () =>
  import( './App/components/management/payment-processing/PaymentProviders' ) );
const PaymentConfigurePage: LazyComponentType = React.lazy( () =>
  import( './App/components/management/payment-processing/PaymentConfigurePage' ) );
const UsersNew: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/Users' ) );
const AddUsers: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/AddUsers' ) );
const EditUser: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/EditUser' ) );
const Bpmn: LazyComponentType = React.lazy( () =>
  import( './App/components/management/bpmn/Bpmn' ) );
const Roles: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/Roles' ) );
const AddNewRole: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/AddNewRole' ) );
const RoleDetails: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/RoleDetails' ) );
const General: LazyComponentType = React.lazy( () =>
  import( './App/components/management/general-settings/General' ) );
const Branding: LazyComponentType = React.lazy( () =>
  import( './App/components/management/brand-settings/BrandSettings' ) );
const Marketplace: LazyComponentType = React.lazy( () =>
  import( './App/components/management/marketplace/Marketplace' ) );
const ProductSettings: LazyComponentType = React.lazy( () => import( './App/components/management/ProductSettings' ) );
const ProductList: LazyComponentType = React.lazy( () =>
    import( './App/components/management/products/product-list/ProductList' ) );
const EditProductPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/management/products/edit-product/EditProductPage' ) );
const ProductPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/management/products/product-page/ProductPage' ) );
const TariffComparison: LazyComponentType = React.lazy( () =>
  import( './App/components/pbm/tariff-comparison/TariffComparison' ) );
const ContactSupport: LazyComponentType = React.lazy( () =>
  import( './App/components/help-center/ContactSupport' ) );

export const pbmRoutes: RouteDescriptor[] = [
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/payment-providers',
    component: PaymentProviders,
    requiredPermissions: [ p.is_ListPolicies ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/payment-providers/:providerName',
    component: PaymentConfigurePage,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/workflow-editor/new',
    component: NewWorkFlow,
    requiredPermissions: [ p.is_ListVwbInbox ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/workflow-editor',
    component: WorkFlowList,
    requiredPermissions: [ p.is_ListVwbInbox ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/workflow-monitor',
    component: WorkflowInstanceListPage,
    requiredPermissions: [ p.is_ListVwbInbox ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/workflow-editor/:workFlowCode?/:versionNumber(\\d+)?',
    component: WorkFlowView,
    requiredPermissions: [ p.is_ListVwbInbox ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/workflow-monitor/'
      +':workFlowCode?/:versionNumber(\\d+)?/:instanceCode?',
    component: WorkFlowMonitorView,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/home',
    component: PBMHome,
    requiredPermissions: [ p.pbm_all ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/scenario-analyses',
    component: ScenarioAnalyses,
    requiredPermissions: [ p.pbm_scenarioanalyses_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/scenario-analyses/new',
    component: CreateScenarioAnalysis,
    requiredPermissions: [ p.pbm_scenarioanalyses_create ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/scenario-analyses/:code',
    component: ScenarioAnalysisDetails,
    requiredPermissions: [ p.pbm_scenarioanalyses_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/dashboards',
    component: Dashboards,
    requiredPermissions: [ p.pbm_dashboard_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/dashboards/new-dashboard',
    component: CreateDashboard,
    requiredPermissions: [ p.pbm_dashboard_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/dashboards/edit-dashboard/:code',
    component: EditDashboard,
    requiredPermissions: [ p.pbm_dashboard_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/dashboards/:dashboardCode',
    requiredPermissions: [ p.pbm_dashboard_view ],
    component: DashboardDetails,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/alerts',
    component: Alerts,
    requiredPermissions: [ p.pbm_alerts_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/alerts/add-new-alert',
    component: AddNewAlert,
    requiredPermissions: [ p.pbm_alerts_create ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/alerts/:alertCode',
    component: EditAlert,
    requiredPermissions: [ p.pbm_alerts_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/alerts/:alertCode/history',
    component: AlertHistories,
    requiredPermissions: [ p.pbm_alerts_view ],
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/alerts/:alertCode/history/:historyId',
    requiredPermissions: [ p.pbm_alerts_view ],
    component: ViewDashboard,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/reports',
    requiredPermissions: [ p.pbm_reports_view ],
    component: Reports,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/reports/:reportCode/history',
    requiredPermissions: [ p.pbm_reports_view ],
    component: ReportHistory,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/tariffcomparisons',
    requiredPermissions: [ p.pbm_tariffcomparisons_view ],
    component: TariffComparison,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings',
    component: GeneralSettings,
    requiredPermissions: [ p.tenants_users_all ],
    componentProps: {
      baseUrl: '/premium-benchmarking',
    },
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/users',
    component: UsersNew,
    requiredPermissions: [ p.tenants_users_view ],
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/users/add-users',
    component: AddUsers,
    requiredPermissions: [ p.tenants_users_view ],
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/users/:code',
    component: EditUser,
    requiredPermissions: [ p.tenants_users_view ],
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/bpmn-example',
    component: Bpmn,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/roles',
    component: Roles,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/roles/new-role',
    component: AddNewRole,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/roles/:roleId',
    component: RoleDetails,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/general',
    component: General,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/branding',
    component: Branding,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/general-settings/marketplace',
    component: Marketplace,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/product-settings',
    component: ProductSettings,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/product-settings/product-list',
    component: ProductList,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/product-settings/product/:productId(\\d+)',
    component: EditProductPage,
    productId: pbmID,
  },
  { hasLayout: true,
    exact: true,
    path: '/premium-benchmarking/product-settings/product/:productId(\\d+)?/:versionId(\\d+)?',
    component: ProductPage,
    productId: pbmID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/support',
    component: ContactSupport,
    productId: pbmID,
  },
];

export default pbmRoutes;
