import React from 'react';
import { IAppPermissionDict, PermissionType } from './permissions/interfaces';
import { permissionDict } from './permissions/permission-dict';

const matchPermission = ( pattern: string, permission: string ): boolean => {
  if ( pattern === '*.*' || pattern === '*.*.*' ) {
    return true;
  }

  const patternParts = pattern.split( '.' );
  const permParts = permission.split( '.' );

  return patternParts.every( ( part, index ) => {
    const patternLength = patternParts.length;

    if ( part === '*' && index === patternLength - 1 ) {
      return true;
    }

    if ( patternParts[0] === permParts[0] &&
      patternParts[1] === '*' &&
      patternParts[patternLength-1] === permParts[patternLength-1] ) {

      return true;
    }

    return part === permParts[index];
  } );
};

export const hasRequiredPermissions = (
  allPermissions: PermissionType[],
  requiredPermissions: PermissionType[] ): boolean => {

  // If user have all Permissions
  const adminIdx = allPermissions.findIndex( ( pr ) => pr === permissionDict.admin );
  if ( adminIdx !== -1 ) {
    return true;
  }

  if ( requiredPermissions.length === 0 ) {
    return true;
  }

  return requiredPermissions.every( ( checkingPermission ) => {
    // split prefix
    const prefixEndsAtIndexOld = checkingPermission.indexOf( ':' );

    if ( prefixEndsAtIndexOld !== -1 ) {
      const prefix = checkingPermission.substring( 0, prefixEndsAtIndexOld );
      const idx = allPermissions
        .filter( ( ptf ) => ptf.startsWith( prefix ) )
        .findIndex( ( ptt ) => {
          return ptt === `${prefix}:*` || ptt === checkingPermission;
        } );
      return idx !== -1;
    }

    const splitPermission = checkingPermission.split( '.' );

    if( splitPermission && splitPermission.length >= 2 ) {
      return allPermissions.some( ( pattern: string ) => matchPermission( pattern, checkingPermission ) );
    }

    return false;
  } );
};

export const AppPermissionDictContext: React.Context<IAppPermissionDict> = React.createContext( permissionDict );

export const useAppPermissionDict = (): IAppPermissionDict => {
  return React.useContext( AppPermissionDictContext );
};
