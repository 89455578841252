import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppUserProducts } from '../providers/AppProductProvider';
import { useAppUser } from 'App/components/utils/providers/AppUserProvider';
import { isEmptyArray } from 'types';
import { useTranslation } from 'react-i18next';

/**
 * This Component calculates landing page and redirect to it.
 * Landing page depends on menu items fetched from user products.
 * This component goal is to redirect to langing page.
 * This component renders nothing.
 */
export const LandingPageResolver: React.FC = () => {
  const { t } = useTranslation( [ 'base' ] );
  const userProducts = useAppUserProducts();
  const currentUser = useAppUser();
  const history = useHistory();
  const { url } = useRouteMatch();
  const landingPage = React.useMemo( () => {
    const menuItems = userProducts
      .map( ( product ) => product.menuItems.filter( ( item ) => !item.nestedApplication ) )
      .filter( ( items ) => items.length > 0 )
      .map( ( items ) => items[0] );
    const prefix = url.replace( /\/+\s*$/, '' );
    const firstMenuItem = menuItems[0];

    if ( !firstMenuItem || isEmptyArray( currentUser.userPermissions ) ) {
      return '';
    }

    const firstItemChildren = firstMenuItem?.children;
    const path = ( firstItemChildren ? firstItemChildren[ 0 ].url! : firstMenuItem.url! )
      .replace( /^\/+/, '' );
    return prefix + '/' + path;
  }, [ currentUser, url, userProducts ] );

  React.useEffect( () => {
    if ( !landingPage ) {
      const prefix = url.replace( /\/+\s*$/, '' );
      history.replace( prefix + '/noaccess', { messageKey: 'noAccessUser' } );
      return;
    }

    history.replace( landingPage );
  }, [ history, landingPage, t, url ] );

  return (
    <React.Fragment />
  );
};
