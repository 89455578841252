import React from 'react';
import { matchPath } from 'react-router';

import { LazyComponentType, RouteDescriptor } from './types';
import { permissionDict as p } from 'Services/permissions/permission-dict';
import { eisPID } from 'App/components/utils/providers/AppProductProvider';
import pbmRoutes from './pbm-routes';

// Components with layout
const Home: LazyComponentType = React.lazy( () => import( './App/components/other/eis-home/EISHome' ) );
const HomePage: LazyComponentType = React.lazy( () => import( './App/components/other/Home' ) );
const ContactSupport: LazyComponentType = React.lazy( () => import( './App/components/help-center/ContactSupport' ) );
const Profile: LazyComponentType = React.lazy( () => import( './App/components/user-profile/UserProfile' ) );
const CustomerList: LazyComponentType = React.lazy( () => import( './App/components/partner/customer/CustomerList' ) );
const EmailEditor: LazyComponentType = React.lazy( () =>
import( './App/components/email-document-editor/email/EmailEditor' ) );
const DocxEditor: LazyComponentType = React.lazy( () =>
  import( './App/components/email-document-editor/docx-template/DocxEditor' ) );
const StaticEditor: LazyComponentType = React.lazy( () =>
  import( './App/components/email-document-editor/static/StaticEditor' ) );
const DocumentEditor: LazyComponentType = React.lazy( () =>
  import( './App/components/email-document-editor/document/DocumentEditor' ) );
const TicketsContainerPage: LazyComponentType = React.lazy( () =>
  import( './App/components/tickets/TicketsContainerPage' ) );
const CustomerDetails: LazyComponentType = React.lazy( () =>
  import( './App/components/partner/customer/CustomerDetails' ) );
const LeadsList: LazyComponentType = React.lazy( () => import( './App/components/partner/leads/LeadsList' ) );
const DynamicLeadPage: LazyComponentType =
  React.lazy( () => import( './App/components/partner/leads/DynamicLeadPage' ) );
const CreatePolicy: LazyComponentType = React.lazy( () => import( './App/components/policy/forms/CreatePolicy' ) );
const CustomerListPagination: LazyComponentType = React.lazy( () => import( './App/components/examples/Pagination' ) );
const TabsExamplePage: LazyComponentType = React.lazy( () => import( './App/components/examples/Tabs' ) );
const ChartsExamplePage: LazyComponentType = React.lazy( () => import( './App/components/examples/ChartsExample' ) );
const PolicyListNew: LazyComponentType = React.lazy( () => import( './App/components/policy/PolicyListNew' ) );
const VwbInboxList: LazyComponentType = React.lazy( () => import( './App/components/vwb-inbox/VwbInboxList' ) );
const FzvInboxList: LazyComponentType = React.lazy( () => import( './App/components/fzv-inbox/FzvInboxList' ) );
const NewWorkFlow: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/AddNew' ) );
const WorkFlowList: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/MainList' ) );
const WorkFlowView: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-editor/ViewDetails' ) );
const WorkflowInstanceListPage: LazyComponentType = React.lazy( () =>
  import( './App/components/management/workflow-instance-monitor/workflow-instance-list/WorkflowInstanceListPage' ) );
const WorkFlowMonitorView: LazyComponentType = React.lazy( () =>
  import(
    './App/components/management/workflow-instance-monitor/workflow-instance-details/WorkFlowInstanceDetailsPage' ),
);
const VwbDetailsPage: LazyComponentType = React.lazy( () =>
import( './App/components/vwb-inbox/vwb-details-page/VwbDetailsPage' ) );
const FzvDetailsPage: LazyComponentType = React.lazy( () =>
import( './App/components/fzv-inbox/fzv-details-page/FzvDetailsPage' ) );
const DynamicPolicyPage: LazyComponentType = React.lazy( () => import( './App/components/policy/DynamicPolicyPage' ) );
const Management: LazyComponentType = React.lazy( () => import( './App/components/management/Management' ) );
const DashboardManagerPage: LazyComponentType = React.lazy(
  () => import( './App/components/management/DashboardManagerPage' ),
);
const NoAccess: LazyComponentType = React.lazy( () => import( './App/components/NoAccess' ) );
const GeneralSettings: LazyComponentType = React.lazy( () =>
  import( './App/components/management/GeneralSettings' ) );
const PaymentProviders: LazyComponentType = React.lazy( () =>
  import( './App/components/management/payment-processing/PaymentProviders' ) );
const PaymentConfigurePage: LazyComponentType = React.lazy( () =>
  import( './App/components/management/payment-processing/PaymentConfigurePage' ) );
const Organisations: LazyComponentType = React.lazy( () =>
    import( './App/components/management/organizations/Organizations' ) );
const InviteOrganizationWithContext: LazyComponentType = React.lazy( () =>
    import( './App/components/management/organizations/InviteOrganization' ) );
const UsersNew: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/Users' ) );
const AddUsers: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/AddUsers' ) );
const EditUser: LazyComponentType = React.lazy( () =>
  import( './App/components/management/users/EditUser' ) );
const Bpmn: LazyComponentType = React.lazy( () =>
  import( './App/components/management/bpmn/Bpmn' ) );
const Roles: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/Roles' ) );
const AddNewRole: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/AddNewRole' ) );
const RoleDetails: LazyComponentType = React.lazy( () =>
  import( './App/components/management/roles/RoleDetails' ) );
const General: LazyComponentType = React.lazy( () =>
  import( './App/components/management/general-settings/General' ) );
const Branding: LazyComponentType = React.lazy( () =>
  import( './App/components/management/brand-settings/BrandSettings' ) );
const Marketplace: LazyComponentType = React.lazy( () =>
  import( './App/components/management/marketplace/Marketplace' ) );
const ProductSettings: LazyComponentType = React.lazy(
  () => import( './App/components/management/ProductSettings' ) );
const CustomFieldFormDemo: LazyComponentType = React.lazy( () =>
  import( './App/components/custom-fields/CustomFieldFormDemo' ) );
const ProductList: LazyComponentType = React.lazy(
  () => import( './App/components/management/products/product-list/ProductList' ) );
const AppFormikDemo: LazyComponentType = React.lazy(
  () => import( './App/components/utils/forms/AppFormikDemo' ) );
const AppFormikDemoTwo: LazyComponentType = React.lazy( () =>
  import( './App/components/utils/forms/AppFormikDemoTwo' ) );
const ReactHookFormExample: LazyComponentType = React.lazy( () =>
  import( './App/components/examples/ReactHookFormExample' ) );
const EditProductPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/management/products/edit-product/EditProductPage' ) );
const ManageProductLanguagePage: LazyComponentType = React.lazy( () =>
    import ( './App/components/management/products/manage-languages/ManageProductLanguagePage' ) );
const ProductPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/management/products/product-page/ProductPage' ) );
const StateManagerDemoPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/examples/state-manager/DemoPage' ) );
const NotificationsDemoPage: LazyComponentType = React.lazy( () =>
  import ( './App/components/examples/notifications/DemoPage' ) );
const FinanceDashboard: LazyComponentType = React.lazy( () =>
  import( './App/components/management/finance-management/FinanceDashboard' ) );
const FinanceInvoicesList: LazyComponentType = React.lazy( () =>
  import( './App/components/management/finance-management/invoices/FinanceInvoicesList' ) );
const DownloadPage: LazyComponentType = React.lazy( () =>
  import( './App/components/other/download/DownloadPage' ) );

const CreateClaimContainer = React.lazy( () =>
  import( 'App/components/management/claims/claim-details/CreateClaimContainer' ) );
const EditClaimContainer = React.lazy( () =>
  import( 'App/components/management/claims/claim-details/EditClaimContainer' ) );
const ListClaimPage = React.lazy( () =>
import ( 'App/components/management/claims/ListClaimPage' ) );
const CreateApplication = React.lazy( () => import( './App/components/partner/common/BookingFunnel' ) );
const ReleaseNotesPage = React.lazy( () => import( './App/components/other/ReleaseNotesPage' ) );
const EditApplication = React.lazy( () => import( './App/components/partner/common/EditBookingFunnel' ) );

export const fzvInboxUrl = '/motor/fzv';

export const mainRoutes: RouteDescriptor[] = [
  ...pbmRoutes,
  {
    hasLayout: true,
    exact: true,
    path: '/profile',
    component: Profile,
    requiredPermissions: [ p.eis_settings_create ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/dashboard',
    component: Home,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.eis_products_view ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_products_view ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/dashboard',
    component: HomePage,
    requiredPermissions: [ p.demo_view ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/support',
    component: ContactSupport,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/customers',
    component: CustomerList,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.policy_management_policies_view ],
      [ p.partner_management_all ],
      [ p.partner_management_partners_all ],
      [ p.partner_management_partners_view ],
      [ p.partner_management_partners_create ],
      [ p.partner_management_partners_update ],
      [ p.partner_management_partners_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: false,
    path: '/tickets',
    component: TicketsContainerPage,
    requiredPermissions: [ p.eis_all ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/pagination',
    component: CustomerListPagination,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/customers/:customerCode',
    component: CustomerDetails,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.partner_management_all ],
      [ p.partner_management_partners_all ],
      [ p.partner_management_partners_view ],
      [ p.partner_management_partners_create ],
      [ p.partner_management_partners_update ],
      [ p.partner_management_partners_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/customers/:customer_code/create-policy/:product_id',
    component: CreatePolicy,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.policy_management_policies_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/customers/:customerCode/create-application/:productId(\\d+)/:productVersionId(\\d+)?/:bfCode?',
    component: CreateApplication,
    requiredPermissions: [
      [ p.eis_policy_create ],
      [ p.eis_leads_create ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_leads_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/examples/tabs',
    component: TabsExamplePage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: false,
    path: '/editor/emails',
    component: EmailEditor,
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.notification_management_email_templates_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: false,
    path: '/editor/documents',
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.document_management_templates_update ],
      [ p.document_management_layouts_update ],
    ],
    component: DocumentEditor,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: false,
    path: '/editor/static-documents',
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.document_management_documents_update ],
    ],
    component: StaticEditor,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: false,
    path: '/editor/docx-documents',
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.document_management_docx_templates_update ],
    ],
    component: DocxEditor,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/examples/charts',
    component: ChartsExamplePage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/leads',
    component: LeadsList,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.eis_policy_create ],
      [ p.eis_leads_view ],
      [ p.eis_leads_create ],
      [ p.eis_leads_all ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_policies_update ],
      [ p.policy_management_policies_delete ],
      [ p.policy_management_leads_view ],
      [ p.policy_management_leads_create ],
      [ p.policy_management_leads_update ],
      [ p.policy_management_leads_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/leads/:leadCode',
    component: DynamicLeadPage,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.eis_policy_create ],
      [ p.eis_leads_view ],
      [ p.eis_leads_create ],
      [ p.eis_leads_all ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_leads_view ],
      [ p.policy_management_leads_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/leads/create-application/:productId(\\d+)/:productVersionId(\\d+)?/:bfCode?',
    component: CreateApplication,
    requiredPermissions: [
      [ p.eis_policy_create ],
      [ p.eis_leads_create ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_leads_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/leads/:leadCode/edit-application/:productId(\\d+)/:productVersionId(\\d+)?/:bfCode?',
    component: EditApplication,
    componentProps: {
      mode: 'edit',
    },
    requiredPermissions: [
      [ p.eis_policy_create ],
      [ p.eis_leads_create ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_leads_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/leads/:leadCode/view-application/:productId(\\d+)/:productVersionId(\\d+)?/:bfCode?',
    component: EditApplication,
    componentProps: {
      mode: 'view',
    },
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.eis_policy_create ],
      [ p.eis_leads_view ],
      [ p.eis_leads_create ],
      [ p.eis_leads_all ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_leads_view ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_leads_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/policies',
    component: PolicyListNew,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_policies_create ],
      [ p.policy_management_policies_update ],
      [ p.policy_management_policies_all ],
      [ p.policy_management_all ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/policies/:policyCode',
    component: DynamicPolicyPage,
    requiredPermissions: [
      [ p.eis_policy_view ],
      [ p.policy_management_policies_view ],
      [ p.policy_management_policies_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/payment-providers',
    component: PaymentProviders,
    requiredPermissions: [ p.eis_all ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/payment-providers/:providerName',
    component: PaymentConfigurePage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/policies/:policyCode/motor/vwb/:vwbMessageId',
    component: VwbDetailsPage,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/policies/:policyCode/motor/fzv/:fzvMessageId',
    component: FzvDetailsPage,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/motor/fzv/',
    component: FzvInboxList,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/motor/fzv/:fzvMessageId',
    component: FzvDetailsPage,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/motor/vwb',
    component: VwbInboxList,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: `${fzvInboxUrl}/:fzvMessageId`,
    component: FzvDetailsPage,
    requiredPermissions: [
      [ p.gdv_view ],
      [ p.gdv_view_dot ],
      [ p.gdv_all ],
      [ p.gdv_management_operations_all ],
      [ p.gdv_management_operations_view ],
      [ p.gdv_management_operations_create ],
      [ p.gdv_management_operations_update ],
      [ p.gdv_management_operations_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/workflow-editor/new',
    component: NewWorkFlow,
    requiredPermissions: [
      [ p.eis_settings_view ],
      [ p.process_management_workflows_view ],
      [ p.process_management_workflows_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/workflow-editor',
    component: WorkFlowList,
    requiredPermissions: [
      [ p.eis_settings_view ],
      [ p.process_management_workflows_all ],
      [ p.process_management_workflows_view ],
      [ p.process_management_workflows_create ],
      [ p.process_management_workflows_update ],
      [ p.process_management_workflows_delete ],
      [ p.process_management_workflows_run ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/workflow-monitor',
    component: WorkflowInstanceListPage,
    requiredPermissions: [
      [ p.eis_settings_view ],
      [ p.process_management_workflows_all ],
      [ p.process_management_workflows_view ],
      [ p.process_management_workflows_create ],
      [ p.process_management_workflows_update ],
      [ p.process_management_workflows_delete ],
      [ p.process_management_workflows_run ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/workflow-editor/:workFlowCode?/:versionNumber(\\d+)?',
    component: WorkFlowView,
    requiredPermissions: [
      [ p.eis_settings_view ],
      [ p.process_management_workflows_view ],
      [ p.process_management_workflows_update ],
      [ p.process_management_workflows_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/workflow-monitor/:workFlowCode?/:versionNumber(\\d+)?/:instanceCode?',
    component: WorkFlowMonitorView,
    requiredPermissions: [
      [ p.eis_settings_view ],
      [ p.process_management_workflows_view ],
      [ p.process_management_workflows_update ],
      [ p.process_management_workflows_delete ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/motor/vwb/:vwbMessageId',
    component: VwbDetailsPage,
    requiredPermissions: [ [ p.gdv_view ], [ p.gdv_view_dot ], [ p.gdv_management_operations_view ] ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management',
    component: Management,
    requiredPermissions: [ p.eis_all ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/dashboard',
    component: DashboardManagerPage,
    requiredPermissions: [ p.demo_view ],
    componentProps: {
      productType: 'mgnt',
    },
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings',
    component: GeneralSettings,
    componentProps: {
      baseUrl: '/management',
    },
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/organizations',
    component: Organisations,
    requiredPermissions: [
      [ p.tenants_users_view ],
      [ p.tenant_management_organizations_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/organizations/invite-organization',
    component: InviteOrganizationWithContext,
    requiredPermissions: [
      [ p.tenants_users_view ],
      [ p.tenant_management_organizations_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/organizations/invite-organization/:code',
    component: InviteOrganizationWithContext,
    requiredPermissions: [
      [ p.tenants_users_view ],
      [ p.tenant_management_organizations_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/users',
    component: UsersNew,
    requiredPermissions: [
      [ p.tenants_users_view ],
      [ p.tenant_management_users_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/users/add-users',
    component: AddUsers,
    requiredPermissions: [
      [ p.tenants_users_create ],
      [ p.tenant_management_users_create ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/users/:code',
    component: EditUser,
    requiredPermissions: [
      [ p.tenants_users_view ],
      [ p.tenants_users_update ],
      [ p.tenant_management_users_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/bpmn-example',
    component: Bpmn,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/roles',
    requiredPermissions: [
      [ p.tenants_roles_view ],
      [ p.identity_management_roles_update ],
    ],
    component: Roles,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/roles/new-role',
    requiredPermissions: [
      [ p.tenants_roles_create ],
      [ p.identity_management_roles_create ],
    ],
    component: AddNewRole,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/roles/:roleId',
    requiredPermissions: [
      [ p.tenants_roles_view ],
      [ p.tenants_roles_update ],
      [ p.identity_management_roles_update ],
    ],
    component: RoleDetails,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/general',
    component: General,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/branding',
    component: Branding,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/general-settings/marketplace',
    component: Marketplace,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/product-settings',
    component: ProductSettings,
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.eis_products_create ],
      [ p.policy_management_products_update ],
    ],
    componentProps: {
      baseUrl: '/management',
    },
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/finance-management/finance-report',
    component: FinanceDashboard,
    requiredPermissions: [ [ p.eis_finance ], [ p.reporting_dashboards_view ] ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/finance-management/finance-invoices',
    component: FinanceInvoicesList,
    requiredPermissions: [ [ p.feature_beta ], [ p.admin ], [ p.billing_management_invoices_update ] ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/custom-fields/demo',
    component: CustomFieldFormDemo,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/formik-demo',
    component: AppFormikDemo,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/formik-demo-two',
    component: AppFormikDemoTwo,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/react-hook-form-example',
    component: ReactHookFormExample,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/product-settings/product-list',
    component: ProductList,
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.eis_products_create ],
      [ p.policy_management_products_update ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/noaccess',
    component: NoAccess,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/product-settings/product/:productId(\\d+)',
    component: EditProductPage,
    requiredPermissions: [
      [ p.eis_products_create ],
      [ p.eis_products_all ],
      [ p.policy_management_products_view ],
      [ p.policy_management_products_create ],
      [ p.policy_management_products_update ],
      [ p.policy_management_products_all ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/product-settings/product/:productId(\\d+)/language',
    component: ManageProductLanguagePage,
    requiredPermissions: [
      [ p.eis_products_create ],
      [ p.eis_products_all ],
      [ p.translation_management_translations_create ],
      [ p.translation_management_translations_update ],
      [ p.translation_management_translations_view ],
      [ p.translation_management_translations_delete ],
      [ p.translation_management_all ],
      [ p.translation_management_translations_all ],
    ],
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/management/product-settings/product/:productId(\\d+)?/:versionId(\\d+)?',
    requiredPermissions: [
      [ p.eis_products_view ],
      [ p.eis_products_create ],
      [ p.eis_products_all ],
      [ p.policy_management_products_view ],
      [ p.policy_management_products_create ],
      [ p.policy_management_products_update ],
      [ p.policy_management_products_all ],
    ],
    component: ProductPage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/state-manager-demo',
    component: StateManagerDemoPage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/notifications-demo',
    component: NotificationsDemoPage,
    productId: eisPID,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/claims',
    component: ListClaimPage,
    productId: eisPID,
    requiredPermissions: [
      [ p.claim_management_claims_view ],
      [ p.claim_management_claims_create ],
      [ p.claim_management_claims_update ],
      [ p.claim_management_claims_delete ],
      [ p.claim_management_all ],
      [ p.claim_management_claims_all ],
    ],
  },
  {
    hasLayout: true,
    exact: true,
    path: '/claims/new',
    component: CreateClaimContainer,
    productId: eisPID,
    requiredPermissions: [
      [ p.eis_claims_create ],
      [ p.claim_management_claims_create ],
      [ p.claim_management_all ],
      [ p.claim_management_claims_all ],
    ],
  },
  {
    hasLayout: true,
    exact: true,
    path: '/claims/:claimCode',
    component: EditClaimContainer,
    productId: eisPID,
    requiredPermissions: [
      [ p.claim_management_claims_view ],
      [ p.claim_management_claims_update ],
      [ p.claim_management_all ],
      [ p.claim_management_claims_all ],
    ],
  },
  {
    hasLayout: false,
    exact: true,
    path: '/download/:s3Key',
    component: DownloadPage,
    productId: eisPID,
    doNotDecodeOnReturn: true,
  },
  {
    hasLayout: true,
    exact: true,
    path: '/release-notes',
    component: ReleaseNotesPage,
    productId: eisPID,
  },
];

export default mainRoutes;

export function getReturnUrl( search: string ): string {
  const params = new URLSearchParams( search );
  const backUrl = params.get( 'backUrl' );

  const doNotDecode = backUrl &&
    mainRoutes.filter( ( r ) => r.doNotDecodeOnReturn ).find(
      ( r ) => matchPath( backUrl, r ),
    );

  const returnUrl = (
    doNotDecode ? backUrl! :
      backUrl ? decodeURIComponent( backUrl ) :
        '' );

  return returnUrl;
}
