import React from 'react';
import { IApiService, PagingRequest, ApiResponse } from './base';
import {
  AddUserRequest,
  UsersResponse,
  ListUsersRequest,
  IUserItem,
  IAddUserItem,
  GetTenantPaymentSettingsRequest,
  ITenantSettingsValue,
  ITenantPaymentSettingsValue,
  GetTenantSettingsRequest,
  TenantPaymentSettingsRequest,
  TenantPaymentSettingsResponse,
  SetTenantPaymentSettingsRequest,
  ValidationResponse,
  TenantSettingsRequest,
  TenantSettingsResponse,
  SetTenantSettingsRequest,
  ListScenarioAnalysisRequest,
  DeleteScenarioAnalysisRequest,
  GetScenarioAnalysisRequest,
  CreateScenarioAnalysisRequest,
  UpdateScenarioAnalysisRequest,
  GetInsuranceCompaniesRequest,
  GetDatesRequest,
  ListUserRequest,
  GetUserRequest,
  InviteUsersRequest,
  GetRolesRequest,
  DeleteUserRequest,
  ListInvitedUserRequest,
  AssignRolesRequest,
  DeleteUsersRequest,
  DeleteInviteRequest,
  DeleteInvitesRequest,
  GetChannelListRequest,
  DisableUserRequest,
  DisableUsersRequest,
  EnableUserRequest,
  EnableUsersRequest,
  ListOrganizationRequest,
  ListOrganizationInvitationRequest,
  InviteOrganizationRequest,
  ReInviteOrganizationRequest,
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from './users/users';
import { IApiListResponse } from './policies/new/service';
import { IScenarioAnalysisItem, IScenarioAnalysisItemUpdate,
  IInsuranceCompanies,
  DateItem,
  IChannelItem } from './users/scenario-analysis-interfaces';
import dayjs from 'dayjs';
import {
  IUserDetailsItem, IInviteUsers, IRoleDetails, IInvitedUserDetailsItem,
  IUpdateRoles, Organization, IInviteOrganization, IReInviteOrganization, IUpdateOrganization,
} from './users/user-interfaces';
import { ListItems } from './api';
import { CustomSchema } from './claims/interfaces';
import { ListCustomSchemasRequest } from './claims/requests';

export interface IUserApiService {
  getUsersData( paging: PagingRequest, searchValue: string ): Promise<UsersResponse>;
  addUser( user: IAddUserItem ): Promise<IUserItem>;
  getTenantPaymentSettings( key: string ): Promise<ITenantPaymentSettingsValue[]>;
  setTenantPaymentSettings( settings: TenantPaymentSettingsRequest ): Promise<TenantPaymentSettingsResponse>;
  getTenantSettings( key: string ): Promise<ITenantSettingsValue[]>;
  setTenantSettings( key: TenantSettingsRequest ): Promise<TenantSettingsResponse>;
  validatePaymentSettings ( settings: TenantPaymentSettingsRequest ): Promise<ValidationResponse>;
  getScenarioAnalyses( paging: PagingRequest, searchValue: string ): Promise<IApiListResponse<IScenarioAnalysisItem>>;
  deleteScenarioAnalysis( code: string ): Promise<ApiResponse>;
  getScenarioAnalysis( code: string ): Promise<IScenarioAnalysisItem>;
  createScenarioAnalysis( analysis: IScenarioAnalysisItemUpdate ): Promise<IScenarioAnalysisItem>;
  updateScenarioAnalysis( update: IScenarioAnalysisItemUpdate, code: string ): Promise<IScenarioAnalysisItem>;
  getInsuranceCompanies( productName?: string ): Promise<IInsuranceCompanies[]>;
  getDates( productName?: string ): Promise<DateItem[]>;
  getUsers( paging: PagingRequest, searchValue: string, expand?: string[] )
  : Promise<IApiListResponse<IUserDetailsItem>>;
  getInvitedUsers( paging: PagingRequest, searchValue: string, expand?: string[] )
  : Promise<IApiListResponse<IInvitedUserDetailsItem>>;
  getUser( code: string, expand?: string[] ): Promise<IUserDetailsItem>;
  inviteUsers( users: IInviteUsers ): Promise<ApiResponse>;
  getRoles(): Promise<IRoleDetails[]>;
  deleteUser( userId: number ): Promise<ApiResponse>;
  assignRoles( roles: IUpdateRoles ): Promise<IUserDetailsItem>;
  deleteUsers( ids: number[] ): Promise<ApiResponse>;
  deleteInvite( inviteId: number ): Promise<ApiResponse>;
  deleteInvites( ids: number[] ): Promise<ApiResponse>;
  getChannelList( productName?: string ): Promise<IChannelItem[]>;
  disableUser( userId: number ): Promise<IUserDetailsItem>;
  disableUsers( ids: number[] ): Promise<ApiResponse>;
  enableUser( userId: number ): Promise<IUserDetailsItem>;
  enableUsers( ids: number[] ): Promise<ApiResponse>;
  getOrganizations ( paging: PagingRequest, searchValue: string ): Promise<IApiListResponse<Organization>>;
  getOrganizationInvitations ( paging: PagingRequest, searchValue: string ): Promise<IApiListResponse<Organization>>;
  getOrganization( code: string, expand?: string[] ): Promise<Organization>;
  inviteOrganization ( organization: IInviteOrganization ): Promise<Organization>;
  reInviteOrganization( organization: IReInviteOrganization ): Promise<Organization>;
  updateOrganization ( organization: IUpdateOrganization, code: string ): Promise<Organization>;
  listCustomSchemas( pageParams: PagingRequest ): Promise<ListItems<CustomSchema>>;
}

export class UserApiService implements IUserApiService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  getUsersData( paging: PagingRequest, searchValue: string ): Promise<UsersResponse> {
    return this.api.request( new ListUsersRequest( paging, searchValue ) )
      .then( ( response ) => {
        let items: IUserItem[] = [];
        const { users, nextPageToken } = response;

        if ( users ) {
          items = users;
        }

        const res: UsersResponse = {
          users: items,
          nextPageToken: users ? users.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  addUser( user: IAddUserItem ): Promise<IUserItem> {
    return this.api.request( new AddUserRequest( user ) )
      .then( ( response ) => {
        const userDetails = {
          ...response.user,
        };
        return userDetails;
      } );
  }

  getTenantPaymentSettings( key: string ): Promise<ITenantPaymentSettingsValue[]> {
    return this.api.request( new GetTenantPaymentSettingsRequest( key ) )
      .then( ( { values } ) => {
        return values;
      } );
  }

  setTenantPaymentSettings( settings: TenantPaymentSettingsRequest ): Promise<TenantPaymentSettingsResponse> {
    return this.api.request( new SetTenantPaymentSettingsRequest( settings ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getTenantSettings( key: string ): Promise<ITenantSettingsValue[]> {
    return this.api.request( new GetTenantSettingsRequest( key ) )
      .then( ( { values } ) => {
        return values;
      } );
  }

  setTenantSettings( settings: TenantSettingsRequest ): Promise<TenantSettingsResponse> {
    return this.api.request( new SetTenantSettingsRequest( settings ) )
      .then( ( response ) => {
        return response;
      } );
  }

  validatePaymentSettings ( settings: TenantPaymentSettingsRequest ): Promise<ValidationResponse> {
    //TODO: endpoint is not ready yet, so just returning errorCode 0 for UI
    return Promise.resolve( { errorCode: 0 } );
  }

  getScenarioAnalyses( paging: PagingRequest, searchValue: string ): Promise<IApiListResponse<IScenarioAnalysisItem>> {
    return this.api.request( new ListScenarioAnalysisRequest( paging, searchValue ) )
      .then( ( response ) => {
        let data: IScenarioAnalysisItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          data = items.map( ( item, index ) => {
            const analysisItem: IScenarioAnalysisItem = {
              ...item,
              insuranceStartDate: dayjs( item.insuranceStartDate ),
              createdAt: new Date( item.createdAt! ),
              updatedAt: new Date( item.updatedAt! ),
            };

            return analysisItem;
          } );
        }

        const res: IApiListResponse<IScenarioAnalysisItem> = {
          items: data,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  deleteScenarioAnalysis( code: string ): Promise<ApiResponse> {
    return this.api.request( new DeleteScenarioAnalysisRequest( code ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getScenarioAnalysis( code: string ): Promise<IScenarioAnalysisItem> {
    return this.api.request( new GetScenarioAnalysisRequest( code ) )
      .then( ( response ) => {
        const { scenarioAnalysis } = response;
        const details = {
          ...scenarioAnalysis,
          insuranceStartDate: dayjs( scenarioAnalysis.insuranceStartDate ),
          createdAt: dayjs( scenarioAnalysis.createdAt ),
          updatedAt: dayjs( scenarioAnalysis.updatedAt ),
        };

        return details;
      } );
  }

  createScenarioAnalysis( analysisDetails: IScenarioAnalysisItemUpdate ): Promise<IScenarioAnalysisItem> {
    return this.api.request( new CreateScenarioAnalysisRequest( analysisDetails ) )
      .then( ( response ) => {
        const { scenarioAnalysis } = response;
        const details = {
          ...scenarioAnalysis,
          insuranceStartDate: dayjs( scenarioAnalysis.insuranceStartDate ),
          createdAt: dayjs( scenarioAnalysis.createdAt ),
          updatedAt: dayjs( scenarioAnalysis.updatedAt ),
        };

        return details;
      } );
  }

  updateScenarioAnalysis( update: IScenarioAnalysisItemUpdate, code: string ): Promise<IScenarioAnalysisItem> {
    return this.api.request( new UpdateScenarioAnalysisRequest( update, code ) )
      .then( ( response ) => {
        const { scenarioAnalysis } = response;
        const details = {
          ...scenarioAnalysis,
          insuranceStartDate: dayjs( scenarioAnalysis.insuranceStartDate ),
          createdAt: dayjs( scenarioAnalysis.createdAt ),
          updatedAt: dayjs( scenarioAnalysis.updatedAt ),
        };

        return details;
      } );
  }

  getInsuranceCompanies( productName?: string ): Promise<IInsuranceCompanies[]> {
    return this.api.request( new GetInsuranceCompaniesRequest( productName ) )
      .then( ( response ) => {
        const { items } = response;
        return items;
      } );
  }

  getDates( productName?: string ): Promise<DateItem[]> {
    return this.api.request( new GetDatesRequest( productName ) )
      .then( ( response ) => {
        const { items } = response;
        return items.reverse();
      } );
  }

  getUsers( paging: PagingRequest, searchValue: string, expand?: string[] )
    : Promise<IApiListResponse<IUserDetailsItem>> {
    return this.api.request( new ListUserRequest( paging, searchValue, expand ) )
      .then( ( response ) => {
        let usersData: IUserDetailsItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          usersData = items.map( ( item, index ) => {
            const userItem: IUserDetailsItem = {
              ...item,
              createdAt: new Date( item.createdAt! ),
            };

            return userItem;
          } );
        }

        const res: IApiListResponse<IUserDetailsItem> = {
          items: usersData,
          nextPageToken: usersData ? usersData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } ).catch( ( e ) => {
        const res: IApiListResponse<IUserDetailsItem> = {
          items: [],
          nextPageToken: '',
          error: e,
        };

        return res;
      } );
  }

  getInvitedUsers( paging: PagingRequest, searchValue: string, expand?: string[] )
    : Promise<IApiListResponse<IInvitedUserDetailsItem>> {
    return this.api.request( new ListInvitedUserRequest( paging, searchValue, expand ) )
      .then( ( response ) => {
        let usersData : IInvitedUserDetailsItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          usersData = items.map( ( item, index ) => {
            const userItem: IInvitedUserDetailsItem = {
              ...item,
              createdAt: dayjs( item.createdAt ),
              expiresOn: dayjs( item.expiresOn ),
            };
            return userItem;
          } );
        }
        const res: IApiListResponse<IInvitedUserDetailsItem> = {
          items: usersData,
          nextPageToken: usersData ? usersData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getUser( code: string, expand?: string[] ): Promise<IUserDetailsItem> {
    return this.api.request( new GetUserRequest( code, expand ) )
      .then( ( response ) => {
        const { user } = response;
        const details = {
          ...user,
          createdAt: dayjs( user.createdAt ),
        };

        return details;
      } );
  }

  inviteUsers( users: IInviteUsers ): Promise<ApiResponse> {
    return this.api.request( new InviteUsersRequest( users ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getRoles(): Promise<IRoleDetails[]> {
    return this.api.request( new GetRolesRequest() )
      .then( ( response ) => {
        const { items } = response;
        return items;
      } );
  }

  deleteUser( userId: number ): Promise<ApiResponse> {
    return this.api.request( new DeleteUserRequest( userId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  deleteUsers( ids: number[] ): Promise<ApiResponse> {
    return this.api.request( new DeleteUsersRequest( ids ) )
      .then( ( response ) => {
        return response;
      } );
  }

  deleteInvite( inviteId: number ): Promise<ApiResponse> {
    return this.api.request( new DeleteInviteRequest( inviteId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  deleteInvites( ids: number[] ): Promise<ApiResponse> {
    return this.api.request( new DeleteInvitesRequest( ids ) )
      .then( ( response ) => {
        return response;
      } );
  }

  assignRoles( roles: IUpdateRoles ): Promise<IUserDetailsItem> {
    return this.api.request( new AssignRolesRequest( roles ) )
      .then( ( response ) => {
        const { user } = response;
        const details = {
          ...user,
          createdAt: dayjs( user.createdAt ),
        };
        return details;
      } );
  }

  getChannelList( productName?: string ): Promise<IChannelItem[]> {
    return this.api.request( new GetChannelListRequest( productName ) )
      .then( ( response ) => {
        const { items } = response;
        return items;
      } );
  }

  disableUser( userId: number ): Promise<IUserDetailsItem> {
    return this.api.request( new DisableUserRequest( userId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  disableUsers( ids: number[] ): Promise<ApiResponse> {
    return this.api.request( new DisableUsersRequest( ids ) )
      .then( ( response ) => {
        return response;
      } );
  }

  enableUser( userId: number ): Promise<IUserDetailsItem> {
    return this.api.request( new EnableUserRequest( userId ) )
      .then( ( response ) => {
        return response;
      } );
  }

  enableUsers( ids: number[] ): Promise<ApiResponse> {
    return this.api.request( new EnableUsersRequest( ids ) )
      .then( ( response ) => {
        return response;
      } );
  }

  getOrganizations ( paging: PagingRequest, searchValue: string ): Promise<IApiListResponse<Organization>> {
    return this.api.request( ( new ListOrganizationRequest( paging, searchValue ) ) )
      .then( ( response ) => {
        return response;
      } );
  } ;

  async getOrganizationInvitations ( paging: PagingRequest, searchValue: string ):
  Promise<IApiListResponse<Organization>> {
    const response = await this.api.request( ( new ListOrganizationInvitationRequest( paging, searchValue ) ) );

    return response;
  } ;

  getOrganization( code: string, expand?: string[] ): Promise<Organization> {
    return this.api.request( new GetOrganizationRequest( code, expand ) )
      .then( ( response ) => {
        const { organization } = response;
        const details = {
          ...organization,
          // createdAt: dayjs( organization.createdAt ),
        };

        return details;
      } );
  }

  async inviteOrganization ( organization: IInviteOrganization ): Promise<Organization> {
    const response = await this.api.request( ( new InviteOrganizationRequest( organization ) ) );

    return response;
  };

  async reInviteOrganization ( data: IReInviteOrganization ): Promise<Organization> {
    const response = await this.api.request( ( new ReInviteOrganizationRequest( data ) ) );

    return response;
  };

  async updateOrganization ( organization: IUpdateOrganization, code: string ): Promise<Organization> {
    const response = await this.api.request( ( new UpdateOrganizationRequest( organization, code ) ) );

    return response;
  };

  async listCustomSchemas( pageParams: PagingRequest ): Promise<ListItems<CustomSchema>> {
    const response = await this.api.request( new ListCustomSchemasRequest( pageParams ) );

    return response;
  }
};

export const UserApiServiceContext: React.Context<IUserApiService> = React.createContext( undefined as any );

export const useUserApiService = (): IUserApiService => {
  return React.useContext( UserApiServiceContext );
};
